/**
  * Copyright (C) 2023 S&P Global.
  * All Rights Reserved
  * Notice: The information, data, processing technology, software (including source code),
  * technical and intellectual concepts and processes and all other materials provided
  * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
  * (together "S&P Global") and constitute the proprietary and confidential information of
  * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
  * reproduction, distribution, transmission or disclosure of the Property, in any form, is
  * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
  * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
  * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
  * results to be obtained by recipient nor shall S&P Global in any way be liable to any
  * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
  * generality of the foregoing, S&P Global shall have no liability whatsoever to any
  * recipient of the Property, whether in contract, in tort (including negligence), under
  * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
  * recipient as a result of or in connection with such Property, or any course of action
  * determined, by it or any third party, whether or not based on the Property. S&P Global,
  * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
  * and the trademarks of S&P Global used herein are protected by international laws.
  * Any other names may be trademarks of their respective owners.
 */

html,
body {
  height: 100%;
}

@keyframes pcs-loading-progress-half {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes pcs-loading-progress-full {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.pcs-loading-process {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--pcs-color-white-0);
}

.pcs-loading-process_loading .pcs-loading-process__logo {
  transform: scale(1);
  opacity: 1;
}

.pcs-loading-process__logo {
  width: 100px;
  transform: scale(0.5);
  opacity: 0;
}

.pcs-loading-process_loading-full-animation .pcs-loading-process__logo {
  transition: all 500ms ease;
}

.pcs-loading-process_loading .pcs-loading-process__progress-bar {
  opacity: 1;
}

.pcs-loading-process__progress-bar {
  position: relative;

  width: 300px;
  height: 6px;
  margin: 2.5rem 0 1rem;

  background-color: #dddddd; //Need to update after pcs-core package upgrade
  border-radius: 4px;
  opacity: 0;
}

.pcs-loading-process_loading-full-animation .pcs-loading-process__progress-bar {
  transition: all 500ms ease;
}

.pcs-loading-process_loading .pcs-loading-process__progress-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 6px;

  background-color: #d6002a; //TODO: Need to update after pcs-core package upgrade
  border-radius: 4px;
  transform-origin: left;
}

.pcs-loading-process_loading-half-animation
  .pcs-loading-process__progress-bar::before {
  animation: pcs-loading-progress-half 3000ms ease forwards;
}

.pcs-loading-process_loading-full-animation
  .pcs-loading-process__progress-bar::before {
  animation: pcs-loading-progress-full 3000ms ease forwards;
}

.pcs-loading-process_loading .pcs-loading-process__title {
  opacity: 1;
}

.pcs-loading-process__title {
  font-weight: bold;
  font-size: 0.875rem;
  font-family: sans-serif;

  color: var(--pcs-color-gray-7);
  opacity: 0;

  transition: all 500ms ease;
}
